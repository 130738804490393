@media only screen and (min-width: 1025px){
#section5,
#section5 .fp-slide,
#section5 .fp-tableCell{
    height: 400px !important;
    
}
#section1,
#section1 .fp-slide,
#section1 .fp-tableCell{
    height: 700px !important;
   
    
}
#section2,
#section2 .fp-slide,
#section2 .fp-tableCell{
   margin-top: 84px!important;
   
}
#section9,
#section9 .fp-slide,
#section9 .fp-tableCell{
  height: 750px !important;
   
}
#section10,
#section10 .fp-slide,
#section10 .fp-tableCell{
  height: auto !important;
   
}
}


/* $$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$  Footer page Css $$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$*/
.footer{
  width: 100%;
  height: auto;
  background-color: #221c35;
  /* z-index: 1 !important;
  position: fixed; */
}
.footerText{
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: normal;
  text-align: left;
  color: #ffffff;
}
.copyrightText{
  font-size: 14px;
  line-height: 30px;
}
.socialIcon{
  max-width: 50px !important;
  max-height: 20px !important;
}
body{
  font-family: 'Montserrat' !important;
}
input
{
    outline:0px !important;
    -webkit-appearance:none;
    box-shadow: none !important;
}
.ModalCancel{
  float:right;
  color: #e50914;
  cursor: pointer;
  font-size: 20px;
}
/* $$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$  Footer mobile page Css $$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$*/

@media only screen and (max-width: 575px){
  .footerText{
    width: 300px; 
    font-size: 13px;  
    line-height: 1.71;
  }
  .footerImg{
    max-width: 80px;
  }
  .footerImgGoogle{
    max-width: 123px;
    margin-left: 20px;
  }
  .copyrightText{
    text-align: center;
    font-size: 12px;
  }
  .footerLogo{
    max-width: 160px;
    margin-bottom: 15px;
  }
  .headerLogo{
    max-width: 170px;
    margin-bottom: 20px;
    margin-top: 20px;
    margin-left: -14px;
  }
  .imgalingment{
    margin-top: 0px !important;
  }
  .campany a{
    font-size: 13px;
    cursor: pointer;
  }
 
  .location span{
    font-size: 13px;
    width: 200px;
  }
  .connect{
    font-size: 13px;
  }
  .copyrightText{
    font-size: 11px;
    text-align: left;
  }
  .ModalCancel{
    font-size: 14px;
  }
}

/* $$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$  Footer page Tab Css $$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$*/
@media (min-width: 768px) and (max-width: 991.98px){
  .footerLogo{
    max-width: 180px;
  }
  .footerHome{
    position: unset !important;
    bottom: 1 !important;
    left: 0;
    right: 0;
  }
  .footerImg{
    max-width: 70px;
  }
  .footerImgGoogle{
    max-width: 105px;
  }
  .footerText{
    font-size: 13px;
    width: 265px;
  }
  .campany a{
    font-size: 13px;
    cursor: pointer;
  }
  .location span{
    font-size: 13px;
    width: 170px;
  }
  .connect{
    font-size: 13px;
  }
  .copyrightText{
    font-size: 11px;
    text-align: left;
  }

}
/* $$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$  Footer page landscape Mobile Css $$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$*/
@media (min-width: 576px) and (max-width: 767.98px)  {
  .headerLogo{
    max-width: 180px;
    margin-left: -14px;
  }
  .footerHome{
    position: unset !important;
    bottom: 1 !important;
    left: 0;
    right: 0;
  }
  .banner{
    max-width: 265px !important;
    margin-top: 120px !important;
  }
  .bannertop{
    max-width: 265px !important;
    margin-top: 120px !important;
  }
  .tabImage{
    max-width: 265px !important;
    
  }
  .TopText{
    font-size: 32px !important;
    line-height: 50px !important;
  }
  .BtnGetInTouch{
    height: 43px !important;
    width: 145px !important;
    font-size: 13px !important;
  }
  .featuresSubTittel{
    font-size: 14px !important;
    line-height: normal !important;
  }
  .SaasTopic{
    line-height: normal !important;
    font-size: 16px !important;
  }
  .SaasContent{
    font-size: 13px !important;
  }
 .works{
   margin-top: 20px;
 }
  #ROI{
    font-size: 11px;
  }
  #ECO{
    font-size: 11px;
  }
  #SAAS{
    font-size: 11px;
  }
  #Models{
    font-size: 11px;
    padding-left: 0px;
  }
 .Rectangle p{
   font-size: 7px !important;
 }
 .iconImages{
   max-width: 40px !important;
 }
 .icon1{
  max-width: 54px !important;
 }
 .iconImages2{
  max-width: 40px !important;
 }
 #Carousel{

   width: 122% !important;
   margin-left: -75px !important;
 }
 .carousel-Div p{
   font-size:10px !important ;
 }
 .TestimonialsName{
   font-size: 14px !important;
 }
 .footerLogo{
   max-width: 180px;
 }
 .footerText{
   font-size: 9px;
 }
 .footerImg{
   max-width: 50px;
   margin-top: 12px;
 }
 .footerImgGoogle{
   max-width: 90px;
 }
 .imgalingment{
   margin-top: 9px !important;
   margin-left: 12px;
 }
 .campany a{
  font-size: 9px;
  cursor: pointer;
}
.campany p{
  font-size: 13px;
}
.location span{
  font-size: 9px;
  width: 120px;
}
.location p{
  font-size: 13px;
}
.connect{
  font-size: 9px;
}
.Connect p{
  font-size: 13px;
}
.copyrightText{
  font-size: 8px;
  text-align: left;
}
.socialIcon{
  max-width: 44px !important;
}

.mobileTop{
  margin-top: auto !important;
}
.TopSubText{
  font-size: 14px !important;
  line-height: 1.9 !important;
}
.TopSubTextAboutUs{
  font-size: 14px !important;
  line-height: 1.9 !important;
  color: #526e88;
}
.seconSecTittle{
  line-height: normal !important;
}
.seconSecSub{
  font-size: 14px !important;
  line-height: 1.9 !important;
}
.photoes{
  max-width: 70px !important;
}
.Ename{
  font-size: 8px !important;
}
.Etittle{
  font-size: 8px !important;
  line-height: 1.5 !important;
}
.employeImg{
  margin-top: 100px !important;
}
.contactDetails{
  font-size: 11px !important;
}
 
}
/* $$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$  All landscape Tab Css $$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$*/

@media (min-width: 769px) and (max-width: 1023px)  {
  .footerHome{
    position: unset !important;
    bottom: 1 !important;
    left: 0;
    right: 0;
  }
  .banner,.bannertop{
    max-width: 310px !important;
    margin-top: 60px !important;
  }

  .tabImage{
    max-width: 310px !important;
    margin-top: 84px !important;
  }
  .bannerAbout{
    max-width: 420px !important;
    margin-top: 50px;
  }
  .mobileTop{
    margin-top: auto !important;
  }
  .seconSecTittle{
    margin-top:50px;
  }
  .photoes{
    max-width: 142px !important;
  }
  .employeImg{
    margin-top: 50px;
  }
}

/* $$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$  Home page Css $$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$*/
#carouselExampleIndicatorss{
  display: none;
}
#MobileBanner{
  display:none;
}
.TopText{
  font-size: 40px;
  line-height: 60px;
  color: #221c35;
  margin-top: 80px;
}
.banner{
  max-width: 520px;
  margin-top: -50px;
}
.bannertop{
  max-width: 560px;
  margin-top: 0px;
}
.TopSubText{
  color: #526e88;
}
.BtnGetInTouch{
  outline: none;
  width: 243px;
  height: 71px;
  border-radius: 11px;
  border: solid 1.5px #e50914;
  background-color: #e50914;
  color: #ffffff;
}
.sectionTow{
  padding-top: 15%;
}
.customerNumber{
  font-size: 36px;
  font-weight: bold;
  text-align: center;
  margin-top: 20px;
  margin: auto;
  display:block;
}
.subText{
  font-size: 16px;
  font-weight: normal;
  color: #e50914;
  text-align: center;
  margin-bottom: 40%;
}
.featuresTittel{
  font-size: 28px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: 5.6px;
  text-align: center;
  color: #a191b2;
  /* margin-top: -44px; */
}
.featuresSubTittel{
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.78;
  letter-spacing: normal;
  text-align: center;
  color: #526e88;
}
.iconImages{
 margin-top: 10px;
  margin: auto;
  float: none;
  display: block;
  max-width: 60px;
  max-height: 60px;

}
.icons{
  height: 50px;
}
.icon1{
max-width: 79px !important;
}
.Rectangle .iconImages{
  margin-top: 10px;
  margin: auto;
  float: none;
  display: block;
  max-width: 60px;
  max-height: 60px;

}
.iconImages2{
  margin-top: 10px;
  margin: auto;
  float: none;
  display: block;
  max-width: 66px;
  max-height: 60px;
}
.navTitle{
  padding: 10px 0;
  cursor: pointer;
  color: #777;
  margin: auto;
 float: none;
 display: block;
  
}
.navTitleClick{
  padding: 10px 0;
  cursor: pointer;
  color: black;
  border-bottom: 4px solid #e50914;
  text-decoration: none; 
 margin: auto;
 float: none;
 display: block;
 justify-content: center;
max-width: fit-content;
}
.SaasTopic{
color: #221c35;
font-size: 24px;
font-weight: bold;
font-stretch: normal;
font-style: normal;
line-height: 4.13;
letter-spacing: 1.2px;
text-align: left;
color: var(--dark);
}
.tabImage{
  max-width: 580px;
}
.SaasContent{
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
  color: #526e88;
}
.Rectangle{
  width: 291px;
  border-radius: 11px;
  border: solid 0.6px #a191b2;
  background-color: #ffffff;
  cursor: default;
  padding: 30px;
padding-left: 15px;
padding-right: 15px;
}
.service p{
  line-height: 10px !important;
  margin-top:10px ;
}
.Rectangle:hover{
  box-shadow: 0 6px 11px 0 rgba(172, 172, 201, 0.5);
  border: solid 1px #e50914;
  background-color: #ffffff;
}
.Rectangle p{
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: normal;
  text-align: center;
  color: var(--dark);
  top: 21px;
  position: relative;
}
.carousel-Div{
width: 500px;
height: 290px;
background: #FFFFFF;
box-shadow: 0px 6px 11px rgba(172, 172, 201, 0.5);
border-radius: 11px;

}
.carousel-Div:hover{
  box-shadow: 0 6px 11px 0 rgba(172, 172, 201, 0.5);
  border: solid 1px #e50914;
  background-color: #ffffff;
}
.carousel-indicators {
  position: inherit !important;
}

.carousel-indicators li {
  width: 10px !important;
  height: 8px !important;
  border-radius: 100% !important;
  background-color: #e4e4e4 !important;
  border-bottom: 2px solid transparent !important;
  background-clip: unset !important;
  border-top: none !important;
}
.carousel-indicators .active {
  background-color: #e50914 !important;
}
.carousel-Div p{
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: normal;
  text-align: left;
  color: #526e88;
 
}
.carouselImages{
  float: none;
  display: block;
  max-width: 140px;
  max-height: 70px;
}
.carousel-inner{
  overflow: unset !important;
}
.mobile{
  display: none;
}
.desktop{
  display: block;
}
#carouselExampleControls{
  display: none;
}
#carouselExampleIndicators{
  display: none;
}
.fp-slides{
  height: auto;
  z-index: 0 !important;
}
.imgalingment{
  margin-top: -7px;
}
.TestimonialsName{
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: normal;
  text-align: left;
  color: var(--dark);
}
.TestimonialsPost{
  font-size: 11px !important;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.82 !important;
  letter-spacing: normal;
  text-align: left;
  color: #616e7a !important;
}
.flex{
  display: flex !important;
}
/* #section1{
  margin-top: -40px !important;
} */
#section3{
  margin-top: 5%;

}
.footerHome{
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}
.tab{
  display: none !important;
}
.tabNo{
  display: flex;
}
button:focus {outline:0;}
button {
  outline: none !important; 
}
.works{
  margin-top: 2%;
}
.headerLogo{
  margin-top: 48px;
}
.SecondSection{
  margin-top: -20px;
}
.homeFooter{
  margin-top: 8%;
}
/* $$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$  Home page MObile Css $$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$*/

@media only screen and (max-width: 575px){
 #carouselExampleIndicatorss{
  display: block !important; 
 }
 .SecondSection{
  margin-top: 10px !important;
}
 #MobileBanner{
   display: block;
   margin:auto;
   float: none;
 }
 #DesktopBanner{
   display: none;
 }
 .featurePic{
   float: left;
   max-width: 30px;
   margin-right: 8px;
 }
 .featureText{
   font-size: 12px;
 }
 .featureText p{
margin-top: 10px;
 }
  #carouselExampleControls{
    display: block !important;
  }
  #carouselExampleIndicators{
    display: block !important;
  }
    .TopText{
      font-size: 26px;
      text-align: left;
      line-height: 40px;
      margin-top: 30px;
    }
    .TopSubText{
      font-size: 14px;
    }
    .TopSubTextAboutUs{
      font-size: 14px;
      color: #526e88;
    }
  .BtnGetInTouch{
    height: 43px;
    width: 145px;
    font-size: 13px;
  }
  .banner{
    /* width: -moz-available; */
    /* margin-left: 0px !important; */
    max-width: 320px !important;
    margin-top: 0px;
  }
  .subText{
    margin-bottom: 0px;
  }
  .customerNumber{
    font-size: 30px;
    font-weight: bold;
  }
  .iconImages{
    margin-top: 27px;
  }
  .iconImages2{
    margin-top: 27px;
    margin-left: 45%;
    max-width: 55px;
  }
  #section2{
    margin-top: 80px;
  }
  .featuresTittel{
    font-size: 18px;
    letter-spacing: 3.6px !important;
    margin-top: 12px;
  }
  .featuresSubTittel{
    font-size: 14px;
    line-height: 1.43 !important;
  }
  .tabImage{
    /* width: -moz-available; */
    max-width: 320px !important;
    margin: auto;
    display: block;
  }
  .SaasContent{
    font-size: 14px;
    line-height: 1.71;
  }
 .mobile{
   display: block;
 }
 .desktop{
   display: none !important;
 }
 .Rectangle{
   margin: auto;
   max-width: 180px;
   height: 208px;
 }
 .Rectangle p{
   font-size: 14px;
 }
  .caroselLeft{
    margin-top: 80px;
    margin-left: -80px;
    max-width: 20px;
  }
  .caroselRight{
    margin-top: 80px;
    margin-right: -80px;
    max-width: 20px;
  }
  .carousel-Div{
    margin: auto;
    max-width: 300px !important;
  }
  .footerHome{
    position: unset;
    bottom: 0 ;
    left: 0;
    right: 0;
  }
  .tab{
    display: none !important;
  }
  .leftArrow{
    margin-top: 257px;
    margin-left: 183%;
  }
  .leftArrowtwo{
    margin-top: 339px;
    margin-left: 163%;
  }
  
}
/* $$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$  Home page Tab Css $$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$*/

@media (min-width: 768px) and (max-width: 991.98px){

  .headerLogo{
    max-width: 200px;
    margin-left: -14px;
  }
  .testtamonialtext{
    font-size: 12px !important;
  }
  .TestimonialsName{
    font-size: 12px !important;
  }
  .banner{
    max-width: 411px;
    height: 271px;
    margin-left: -60px;
    margin-top: 0px;
  }
  .TopText{
    width: 299px;
    height: 80px;
    font-size: 26px;
    line-height: 1.62;
  }
  .TopSubText{
    width: 279px;
  /* height: 45px; */
  font-size: 15px;
  line-height: 1.73;
  letter-spacing: normal;
  text-align: left;
  }
  .TopSubTextAboutUs{
    /* width: 279px; */
    /* height: 45px; */
    font-size: 15px;
    line-height: 1.73;
    letter-spacing: normal;
    text-align: left;
    color: #526e88;
  }
  .BtnGetInTouch{
    width: 118px;
    height: 35px;
    border-radius: 11px;
    font-size: 10px;
  }
  .customerNumber{
    font-size: 24px;
    font-weight: bold;
  }
  .subText{
    font-size: 15px;
  }
  .works{
    margin-top: 10%;
  }
  .iconImages{
    max-width: 50px;
    max-height: 46px;
  }
  .iconImages2{
    max-width: 50px;
    max-height: 46px;
  }
  .featuresTittel{
    font-size: 24px;
   
  }
  .featuresSubTittel{
    font-size: 14px;
    line-height: 3.57;
    letter-spacing: normal;
  }
  #ROI{
    font-size: 11px;
  }
  #ECO{
    font-size: 11px;
  }
  #SAAS{
    font-size: 11px;
  }
  #Models{
    font-size: 11px;
    padding-left: 0px;
  }
  .SaasContent{ 
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.6;
    letter-spacing: normal;
  }
  .tabImage{
    max-width: 366px;
    margin-top: 95px;
    margin-top: 0px !important;
  }
  .SaasTopic{
    line-height: 2;
    letter-spacing: normal;
    font-size: 17px;
  }
  .Rectangle p{
    font-size: 10px;
    line-height: 1.6;
  }
  .tabNo{
    display: none !important;
  }
  .tab{
  display: flex !important;
}
.contactDetails{
  font-size:12px !important ;
}
 

}

/* $$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$  About Us page Css $$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$*/

.seconSecTittle{
    
    font-size: 30px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.67;
    letter-spacing: normal;
    text-align: left;
    color: var(--dark);
}
.seconSecSub{
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: normal;
  text-align: left;
  color: #526e88;
}
.photoes{
  max-width: 165px;
  margin: auto;
  display: block;
}
.Ename{
  font-size: 12px;
  font-weight: bold;
  float: none;
  margin: auto;
  display: block;
  text-align: center;
 
}
.Etittle{
  font-size: 11px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: center;
  color: #e50914;
}
.aboutusSectionTow{
  padding-top: 100px;
  padding-bottom: 30px;
}
.TopSubText{
font-size:20px;
font-weight: normal;
font-stretch: normal;
font-style: normal;
line-height: 2;
}
.TopSubTextAboutUs{
  font-size:19px;
font-weight: normal;
font-stretch: normal;
font-style: normal;
line-height: 2;
color: #526e88;

}
.bannerAbout{
  /* margin-top: 15px; */
  float: right;
}
.mobileTop{
  margin-top: 0px !important;
}

/* $$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$  About us page MObile Css $$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$*/

@media only screen and (max-width: 575px){
  .seconSecTittle{
    font-size: 20px;
    line-height: 1.5;
  }
  .seconSecSub{
    font-size: 14px;
    line-height: 1.59;

  }.TopSubText{
    font-size: 14px;
    line-height: 1.59;
  }
 .TopSubTextAboutUs{
  font-size: 14px;
  line-height: 1.59;
  text-align: justify;
  color: #526e88;
 }
 .bannerAbout{
margin-bottom: 15px;
margin: auto;
float: none;
display: block;
 }
  .photoes{
    margin: auto;
    float: none;
    display: block;
  }
  .mobileTop{
    margin-top: 0px !important;
  }
  .Ename{
    font-size: 10px;
  }
  .Etittle{
    font-size: 10px;
  }

}
/* $$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$  About us page TAb Css $$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$*/
@media (min-width: 768px) and (max-width: 991.98px){
  .mobileTop{
    margin-top: 0px !important;
  }
  .TopSubText{
    font-size: 14px;
    line-height: 2;
  }
  .seconSecSub{
    font-size: 14px;
    line-height: 2;
  }
  .bannerAbout{
    margin-top: 120px;
  }
  .photoes{
    max-width: 100px;
  }
  .seconSecTittle{
    font-size: 20px;
    margin-top: 50px;
  }
  .employeImg{
    margin-top: 50px;
  }
}

/* $$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$  Model page Css $$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$*/
.ModelTittle{
  font-size: 30px;
  float: none;
  margin: auto;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.06;
  letter-spacing: normal;
  text-align: center;
  color: var(--dark);
}
.ModelsubTittle{
  font-size: 15px;
  float: none;
  margin: auto;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: normal;
  text-align: left;
  color: #526e88;
}
.modelIcon{
  margin: auto;
  float: none;
  display: block;
  max-width: 40px;
}
.contactDetails{
  text-align: center;
  font-size: 14px;
}
input {
  outline: 0;
  border-width: 0 0 2px;
  border-color: #e0e6f8;
  width: 88%;
}
input:focus {
  border-color: #e50914;
}
.BtnSendMessage{
  width: auto;
  height: 65px;
  border-radius: 11px;
  border: solid 1.5px #e50914;
  background-color: #e50914;
  margin: auto;
  color: #ffffff;
  margin: auto;
  float: right;
  padding: 10px;
}
.CancelBtn{
  margin-top: 65px;
  margin-left: 20px;
  font-size: 20px;
  color: #a191b2;
  cursor: pointer;
}
.CancelBtn:hover{
  color: #e50914; 
  font-size: 22px;
}
.emailValidate{
  font-size: 11px;
  color: #e50914;
}
textarea{
  width: 88%;
  outline: 0;
  border-width: 0 0 2px;
  border-color: #e0e6f8;
  height: 50px;
}
textarea:focus{
  border-color: #e50914;
}
button:active {
  background-color: #E8505B; 
 }
/* button:focus {
 background-color: #E8505B; 
} */
.thankyou{
  font-size: 40px;
  font-weight: bold;
  text-align: center;
}
.thankyousub{
  font-size: 20px;
  line-height: 2;
  text-align: center;
  color: #526e88;
}
.thankyouBtn{
  width: 230px;
  height: 60px;
  margin: auto;
  display: block;
  float: none;
  border-radius: 11px;
  border: solid 1.5px #e50914;
  background-color: #e50914;
}
/* $$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$  Modal page MObile Css $$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$*/

@media only screen and (max-width: 575px){
  .ModelTittle{
    font-size: 16px;
    line-height: 1.2;
  }
  .ModelsubTittle{
    font-size: 13px;
  }
  .BtnSendMessage{
    width: auto;
    font-size: 12px;
    height: 50px;
  }
  .CancelBtn{
    font-size: 12px;
  }
  .CancelBtn:hover{
    color: #e50914; 
    font-size: 14px;
  }
  .thankyou{
    font-size: 20px;
  }
  .thankyousub{
    font-size: 12px;
    line-height: normal;
  }
  .thankyouBtn{
    width: 160px;
    height: 40px;
  }
  
}

/* $$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$  terms and policy oage Css $$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$*/
.terms{
  text-align: justify;
}
.terms h3{
  font-size: 24px;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.13;
  letter-spacing: 1.2px;
  text-align: left;
  color: var(--dark);
}
.terms p{
  font-size: 18px;
font-weight: 400;
font-stretch: normal;
font-style: normal;
letter-spacing: normal;
text-align: justify;
color: #526e88;

}
.terms li{
  font-size: 18px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
  color: #526e88;
}

@media only screen and (max-width: 575px){
  .terms h3{
    font-size: 20px;
    line-height: 2.13;
  }
  .terms p{
    font-size: 16px;
  }
  .terms li{
    font-size: 16px;
  }
  .terms h1{
    font-size: 25px;
  }
}